import React from 'react';

function Meats() {
    return (
      <div>
        <h1>Meets</h1>
        <p>New to the area?  Typical meeting spot is at O'reilley on Hastings Way every Friday and Saturday night.</p>
        <p>1417 S Hastings Way, Eau Claire, WI 54701</p>
      </div>
    );
  }

export default Meats;
