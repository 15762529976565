import React from 'react';
import eventPoster from './images/rf_2024.png';
import eventPoster2 from './images/autocross.png';


function Racing() {
  return (
    <div>
      <img src={eventPoster} alt="Event Poster" style={{ maxWidth: '50%', margin: '20px 0' }} />
      <div>
        <a href="https://www.motorsportreg.com/events/cvscc-autocross-saturday-may-11th-2024-rock-falls-raceway-chippewa-valley-051945" target="_blank" rel="noopener noreferrer">
          <img src={eventPoster2} alt="" style={{ maxWidth: '40%', margin: '20px 0' }} />
        </a>
        <p style={{ marginBottom: '50px' }}></p>
      </div>
    </div>
  );
}

export default Racing;