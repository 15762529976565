import React, { useState, useEffect } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';

function InstagramList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch the data from the public directory
    fetch('/instagrams.json')
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error loading the data:', error));
  }, []);

  return (
    <div>
      <h1>Instagram Profiles</h1>
      <ul>
        {data.map(item => (
          <li key={item.id}>
            <a href={item.instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
              {item.instagramHandle}
            </a> ({item.carType})
          </li>
        ))}
      </ul>
    </div>
  );
}

export default InstagramList;
