import React from 'react';

function Instagrams() {
  return (
    <div>
      <h1>Local Gramlins</h1>
      <p>@</p>
    </div>
  );
}

export default Instagrams;