// Layout.js
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import FacebookIcon from '@mui/icons-material/Facebook';
import ForumIcon from '@mui/icons-material/Forum';
import InstagramIcon from '@mui/icons-material/Instagram';
import GridViewIcon from '@mui/icons-material/GridView';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CoffeeIcon from '@mui/icons-material/Coffee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import RecommendedShops from './components/RecommendShops';
import Instagrams from './components/Instagrams';
import Candc from './components/CarsandCoffee';


function Layout({ children }) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Sidebar collapsed={collapsed} className="app">
      <Menu>
       <MenuItem className="menu1" icon={<MenuRoundedIcon onClick={() => setCollapsed(!collapsed)}/>}>
          <h2> Jump To </h2>
        </MenuItem>
        <MenuItem className='MenuItem' 
                  component={<Link to="/" className="link" />}
                  icon={<HomeIcon />}> Home 
        </MenuItem>
        <SubMenu label="Socialize" icon={<GridViewIcon />}>
          <MenuItem className='MenuItem' icon={<ForumIcon />}>
            <a href="https://discord.gg/D3qU4kxzMH" target="_blank" rel="noopener noreferrer">
              Discord
            </a>
          </MenuItem>
          <MenuItem className='MenuItem' icon={<FacebookIcon />}>
            <a href="https://www.facebook.com/groups/3666217550057258" target="_blank" rel="noopener noreferrer">
              ECCE Facebook
            </a>
          </MenuItem>
          <MenuItem className='MenuItem' icon={<FacebookIcon />}>
            <a href="https://www.facebook.com/cvscc.org" target="_blank" rel="noopener noreferrer">
              CVSCC Facebook
            </a>
          </MenuItem>
          <MenuItem className='MenuItem'
                    component={<Link to="/instagrams" className="link" />}
                    icon={<InstagramIcon />}> IG Handles 
          </MenuItem>
        </SubMenu>
        <SubMenu label="Events" icon={<GridViewIcon />}>
          <MenuItem
              component={<Link to="/candc" className="link" />}
              icon={<CoffeeIcon />}>
              Cars and Coffee 
          </MenuItem>
          <MenuItem> Autocrosses </MenuItem>
          <MenuItem> Adhoc Meets </MenuItem>
        </SubMenu>
        <MenuItem 
          component={<Link to="/recommendedshops" className="link" />}
          icon={<CarRepairIcon />}> Recommended Shops
        </MenuItem>
        <MenuItem className='MenuItem' icon={<CalendarMonthIcon />}> Calendar </MenuItem>
      </Menu>
    </Sidebar>

    
  );
}

export default Layout;
