import React from 'react';
import eventPoster from './images/candc.jpg';
import eventPoster2 from './images/cf_downtown.jpg';

function Candc() {
  return (
    <div>
      <img src={eventPoster} alt="Event Poster" style={{ maxWidth: '50%', margin: '20px 0' }} />
      <h2>Other Seasonal Car Show Series</h2>
      <div>
        <img src={eventPoster2} alt="Event Poster" style={{ maxWidth: '30%', margin: '20px 0' }} />
      </div>
    </div>
  );
}

export default Candc;