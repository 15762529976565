import logo from './wheel.png';
import Layout from './Layout';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import FacebookIcon from '@mui/icons-material/Facebook';
import ForumIcon from '@mui/icons-material/Forum';
import InstagramIcon from '@mui/icons-material/Instagram';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CoffeeIcon from '@mui/icons-material/Coffee';
import HomeIcon from '@mui/icons-material/Home';
import SpeedIcon from '@mui/icons-material/Speed';
import RecommendedShops from './components/RecommendShops';
import Candc from './components/CarsandCoffee';
import Racing from './components/Racing';
import Meats from './components/Meats';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import InstagramList from './components/InstagramList';


const Home = () => {
  const [collapsed, setCollapsed] = useState(true);
  const API_KEY = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState(null);
  const [da, setDa] = useState(null);

  const fetchWeather = async () => {
    try {

      const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=eau claire,us&appid=${API_KEY}&units=imperial`);
      const data = await response.json();
      setWeather(data);
      const daValue = calculateDensityAltitude(data);  // <-- Calculate density altitude
      setDa(daValue);  // <-- Set the state of da
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  useEffect(() => {
    fetchWeather();
  }, []);
  return (
    <>
      {weather && (
        <div className='App-home'>
          <h3 className='h1-home'>
          Eau Claire Car Enthusiasts </h3>
          <p>{weather.name}: {capitalizeFirstLetter(weather.weather[0].description)}</p>
          <p>Temperature: {weather.main.temp}°F | Humidity: {weather.main.humidity}% </p>
          <p>Pressure: {weather.main.pressure}hPa</p>
          <p>Density Altitude: {da}ft</p>
        </div>
      )}
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <a
        className="App-link"
        href="https://www.visiteauclaire.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Visit Eau Claire
      </a>
  </header>
  </>
  );
};
  
function App() {
  const [collapsed, setCollapsed] = useState(true);
  const API_KEY = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState(null);
  const [da, setDa] = useState(null);

  return (
    <div className="App">
      <div className="App-container">
      <Sidebar collapsed={collapsed} className="app">
        <Menu>
         <MenuItem className="menu1" icon={<MenuRoundedIcon onClick={() => setCollapsed(!collapsed)}/>}>
            <h2> Jump To </h2>
          </MenuItem>
          <MenuItem className='MenuItem' 
                    component={<Link to="/" className="link" />}
                    icon={<HomeIcon />}> Home 
          </MenuItem>
          <SubMenu label="Socialize" icon={<GroupsIcon />}>
            <MenuItem className='MenuItem' icon={<ForumIcon />}>
              <a href="https://discord.gg/D3qU4kxzMH" target="_blank" rel="noopener noreferrer">
                Discord
              </a>
            </MenuItem>
            <MenuItem className='MenuItem' icon={<FacebookIcon />}>
              <a href="https://www.facebook.com/groups/5677100289002816" target="_blank" rel="noopener noreferrer">
                ECACC
              </a>
            </MenuItem>
            <MenuItem className='MenuItem' icon={<FacebookIcon />}>
              <a href="https://www.facebook.com/groups/1182085806154661" target="_blank" rel="noopener noreferrer">
                Sunday Cruise
              </a>
            </MenuItem>
            <MenuItem className='MenuItem' icon={<FacebookIcon />}>
              <a href="https://www.facebook.com/groups/3666217550057258" target="_blank" rel="noopener noreferrer">
                ECCE
              </a>
            </MenuItem>
            <MenuItem className='MenuItem' icon={<FacebookIcon />}>
              <a href="https://www.facebook.com/cvscc.org" target="_blank" rel="noopener noreferrer">
                CVSCC
              </a>
            </MenuItem>
            <MenuItem className='MenuItem'
                      component={<Link to="/instagrams" className="link" />}
                      icon={<InstagramIcon />}> Instagrams
            </MenuItem>
          </SubMenu>
          <SubMenu label="Events" icon={<EventIcon />}>
            <MenuItem
                component={<Link to="/candc" className="link" />}
                icon={<CoffeeIcon />}>
                Cars and Coffee 
            </MenuItem>
            <MenuItem
                component={<Link to="/racing" className="link" />}
                icon={<SpeedIcon />}>
                Racing Events
            </MenuItem>
            <MenuItem
                component={<Link to="/meets" className="link" />}
                icon={<LunchDiningIcon />}>
                Meats
            </MenuItem>
          </SubMenu>
          <MenuItem 
            component={<Link to="/recommendedshops" className="link" />}
            icon={<CarRepairIcon />}> Recommended Shops
          </MenuItem>
          {/* <MenuItem className='MenuItem' icon={<CalendarMonthIcon />}> Calendar </MenuItem> */}
        </Menu>
      </Sidebar>
      <div className="App-content">

      <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/candc" element={<Candc />} />
              <Route path="/racing" element={<Racing />} />
              <Route path="/recommendedshops" element={<RecommendedShops />} />
              <Route path="/instagrams" element={<InstagramList />} />
              <Route path="/meets" element={<Meats />} />
      </Routes>

    </div>
    </div>
    </div>
  );
}

function calculateDensityAltitude(weather) {
  const pressure = weather.main.pressure;
  const tempC = (weather.main.temp - 32) / 1.8; // Convert F to C
  const humidity = weather.main.humidity;

  // Bard help..
  //const testDensity = (tempC - 15) * 44.69 + pressure * 0.00980665
  //const dewPointTemperatureInCelsius = 25
  //const test2 = testDensity + 0.375 * (dewPointTemperatureInCelsius - 15) * 0.00980665

  // Convert the density altitude from meters to feet.
  //const densityAltitudeInFeet = testDensity * 3.28084;

  // Calculate dew point (in Celsius) using Magnus-Tetens formula
  const b = 17.27;
  const c = 237.7;
  const alpha = ((b * tempC) / (c + tempC)) + Math.log(humidity/100.0);
  const dewPointC = (c * alpha) / (b - alpha);

  // Calculate water vapor pressure (in hPa)
  const waterVaporPressure = 6.11 * Math.pow(10, (7.5 * dewPointC / (237.7 + dewPointC)));

  // Convert pressure from hPa to inHg
  const pressureInHg = pressure * 0.02953;

  // Calculate pressure altitude (PA)
  const PA = (29.92 - pressureInHg) * 1000;

  // Calculate ISA temperature for Eau Claire, WI
  const ISA_Temp = 13.42;

  // Elevation of Eau Claire, WI
  const elevation = 787

  // Calculate virtual temperature
  const virtualTemp = tempC / (1 - ((waterVaporPressure / pressure) * (1 - 0.622)));

  // Calculate density altitude (DA) incorporating humidity
  const DA = PA + (120 * (virtualTemp - ISA_Temp)) + elevation;

  return DA.toFixed(2); // Return DA rounded to two decimal places
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default App;
