import React from 'react';
import rs_baxter from './shops/rs_baxter.jpg';
import twostep from './shops/twostep.png';
import wissota from './shops/wissota.png';
import psimobile from './shops/psi.jpeg';

function RecommendedShops() {
  return (
    <div>
      <h1>Recommended Shops</h1>
      <p>Your car is important to you.  These are our local recommendations...</p>
      <h2>Engine Performance</h2>
      <div>
        <a href="https://www.facebook.com/p/Baxter-Engines-INC-100057142522259/" target="_blank" rel="noopener noreferrer">
          <img src={rs_baxter} alt="" style={{ maxWidth: '40%', margin: '0px 0' }} />
        </a>
        <p style={{ marginBottom: '50px' }}>3910 Morningcrest Rd, Eau Claire, WI 54703</p>
      </div>
      <h2>Detailing</h2>
      <div>
        <a href="https://www.facebook.com/TwoStepDetailing/" target="_blank" rel="noopener noreferrer">
          <img src={twostep} alt="" style={{ maxWidth: '40%', margin: '0px 0' }} />
        </a>
        <p style={{ marginBottom: '50px' }}>Chippewa Falls, WI 54729</p>
      </div>
      <h2>Autobody Work</h2>
      <div>
        <a href="https://wissotacc.com" target="_blank" rel="noopener noreferrer">
          <img src={wissota} alt="" style={{ maxWidth: '40%', margin: '0px 0' }} />
        </a>
        <p style={{ marginBottom: '50px' }}>5939 210th St, Cadott, WI 54727</p>
      </div>
      <div>
      <h2>Wheels and Tires</h2>
      <div>
        <a href="https://www.facebook.com/psi.mobiletire" target="_blank" rel="noopener noreferrer">
          <img src={psimobile} alt="" style={{ maxWidth: '40%', margin: '0px 0' }} />
        </a>
        <p style={{ marginBottom: '50px' }}>Eau Claire, WI</p>
      </div>
      <p style={{ marginBottom: '50px' }}></p>
      </div>
      <h2>Sponsorships</h2>
      <p>Reach out for a spot</p>
    </div>
  );
}

export default RecommendedShops;
